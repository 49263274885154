import {useState} from "react";
import {Link} from "react-router-dom";

import {byKeywords, SearchBox} from "components/ui";
import {Page} from "components/layout";

const links = [
    {"displayName": "Sentral", "keywords": ["sentral"],
		"img": "/logo/sentral.webp", "href": "https://nbscmanlys-h.sentral.com.au"},
    {"displayName": "DET Portal", "keywords": ["det", "doe", "nsw", "portal"],
		"img": "/logo/nsw-government.webp", "href": "https://portal.education.nsw.gov.au"},
    {"displayName": "EDConnect (Log an Online Query)", "keywords": ["det", "doe", "nsw", "edconnect", "log", "online", "query", "issue", "ticket"],
		"img": "/logo/edconnect.webp", "href": "https://nswdoeesmp1.service-now.com/services_gateway"},
    {"displayName": "School Bytes", "keywords": ["schoolbytes"],
		"img": "/logo/school-bytes.webp", "href": "https://online.schoolbytes.education"},
    {"displayName": "edval Daily", "keywords": ["edval", "webdaily"],
		"img": "/logo/edval.webp", "href": "https://my.edval.education"},

    {"displayName": "Office365", "keywords": ["office365", "microsoft"],
		"img": "/logo/office365.webp", "href": "https://office.com"},
    {"displayName": "Outlook", "keywords": ["office365", "microsoft outlook", "email"],
		"img": "/logo/office365-outlook.webp", "href": "https://outlook.office.com/"},
    {"displayName": "Sharepoint", "keywords": ["office365", "microsoft", "sharepoint", "onedrive"],
		"img": "/logo/office365-sharepoint.webp", "href": "https://schoolsnsw.sharepoint.com/sites/northern-beaches-secondary-college-manly-campus"},
    {"displayName": "OneDrive", "keywords": ["office365", "microsoft", "sharepoint", "onedrive"],
		"img": "/logo/office365-onedrive.webp", "href": "https://schoolsnsw-my.sharepoint.com"},
    {"displayName": "Azure Remote Access", "keywords": ["office365", "microsoft", "azure", "remote", "access"],
		"img": "/logo/azure.webp", "href": "https://rdweb.wvd.microsoft.com/arm/webclient"},
    {"displayName": "OneNote", "keywords": ["office365", "microsoft", "onenote"],
		"img": "/logo/office365-onenote.webp", "href": "https://office.com/launch/onenote"},
    {"displayName": "Word", "keywords": ["office365", "microsoft", "word", "docs"],
		"img": "/logo/office365-word.webp", "href": "https://office.com/launch/word"},
    {"displayName": "Powerpoint", "keywords": ["office365", "microsoft", "powerpoint", "slides"],
		"img": "/logo/office365-powerpoint.webp", "href": "https://office.com/launch/powerpoint"},
    {"displayName": "Excel", "keywords": ["office365", "microsoft", "excel", "spreadsheet", "sheets"],
		"img": "/logo/office365-excel.webp", "href": "https://office.com/launch/excel"},
    {"displayName": "Microsoft Forms", "keywords": ["office365", "microsoft", "forms"],
		"img": "/logo/office365-forms.webp", "href": "https://office.com/launch/forms"},

    {"displayName": "Google", "keywords": ["google"],
		"img": "/logo/google.webp", "href": "https://google.com"},
	{"displayName": "Gmail", "keywords": ["google", "gmail", "email"],
		"img": "/logo/gmail.webp", "href": "https://mail.google.com/a/education.nsw.gov.au/"},
    {"displayName": "Google Classroom", "keywords": ["google", "classroom"],
		"img": "/logo/google-classroom.webp", "href": "https://classroom.google.com"},
    {"displayName": "Google Drive", "keywords": ["google", "drive"],
		"img": "/logo/google-drive.webp", "href": "https://drive.google.com"},
    {"displayName": "Google Docs", "keywords": ["google", "docs", "word"],
		"img": "/logo/google-docs.webp", "href": "https://docs.google.com"},
    {"displayName": "Google Slides", "keywords": ["google", "slides", "powerpoint"],
		"img": "/logo/google-slides.webp", "href": "https://slides.google.com"},
    {"displayName": "Google Sheets", "keywords": ["google", "sheets", "excel", "spreadsheet"],
		"img": "/logo/google-sheets.webp", "href": "https://sheets.google.com"},
    {"displayName": "Google Forms", "keywords": ["google", "forms"],
		"img": "/logo/google-forms.webp", "href": "https://forms.google.com"},

    {"displayName": "Papercut", "keywords": ["papercut", "print"],
		"img": "/logo/papercut.webp", "href": "https://papercut.nbscmanlys-h.schools.nsw.edu.au/user"},
    {"displayName": "Turnitin", "keywords": ["turnitin"],
		"img": "/logo/turnitin.webp", "href": "https://www.turnitin.com"},
    {"displayName": "Atomi", "keywords": ["atomi"],
		"img": "/logo/atomi.webp", "href": "https://learn.getatomi.com"},
    {"displayName": "Education Perfect", "keywords": ["education", "perfect"],
		"img": "/logo/education-perfect.webp", "href": "https://www.educationperfect.com"},
    {"displayName": "eLibrary", "keywords": ["library", "elibrary"],
		"img": "/logo/wheelers.webp", "href": "https://manlycampus.wheelers.co"},
    {"displayName": "Library (Oliver)", "keywords": ["library", "oliver"],
		"img": "/logo/library.webp", "href": "https://oliver-19.library.det.nsw.edu.au/4/"},
    {"displayName": "Premier's Reading Challenge", "keywords": ["library", "premier", "premiers", "reading", "challenge"],
		"img": "/logo/premiers-reading-challenge.webp", "href": "https://online.det.nsw.edu.au/prc/studentExperience.html"},
    {"displayName": "Adobe CC", "keywords": ["adobe", "creative", "cloud", "cc", "photoshop", "illustrator", "premiere"],
		"img": "/logo/adobe-cc.webp", "href": "https://creativecloud.adobe.com/cc"},
    {"displayName": "ClickView Online", "keywords": ["clickview"],
		"img": "/logo/clickview.webp", "href": "https://online.clickview.com.au"},
    {"displayName": "LinkedIn Learning", "keywords": ["linkedin", "learning"],
		"img": "/logo/linkedin.webp", "href": "https://www.linkedin.com/learning"},
    {"displayName": "Naplan Online", "keywords": ["naplan", "lockeddown", "lockdown", "browser"],
		"img": "/logo/naplan.webp", "href": "https://www.assessform.edu.au/naplan-online/locked-down-browser"}
];

export function Homepage() {
	let [value, setValue] = useState("");
    return (
        <Page title="Homepage">
			<SearchBox placeholder="Type here to search" value={value} onChange={(e) => setValue(e.target.value)}
					   className="p-3 bg-white rounded shadow"/>
			<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-1 lg:gap-5">
				{links.filter(byKeywords(value, l => l.keywords))
					.map((l, i) =>
						<HomepageLink key={i} displayName={l.displayName} img={l.img} href={l.href}/>)}
			</div>
        </Page>
    );
}

function HomepageLink({displayName, img, href}) {
	return (
		<Link to={href} target="_blank"
			  className="group flex flex-col aspect-square bg-white rounded shadow hover:shadow-xl cursor-pointer z-0 hover:z-10 overflow-hidden">
			<img className="grow w-full object-cover overflow-hidden" src={img} alt={`${displayName} logo`}/>
			<div className="p-2 bg-slate-700 text-center text-xs text-white font-bold truncate">{displayName}</div>
		</Link>
	);
}
